//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BigNumber from "bignumber.js";

import {
  getPosPoolContract,
  conflux,
  Drip,
  getPosAccountByPowAddress,
} from "../../utils/cfx";
import {
  getCfxByVote,
  calculateGasMargin,
  getFee,
  getApy,
  getDateByBlockInterval,
  getMax,
  getPrecisionAmount,
} from "../../utils";

import { CFX_BASE_PER_VOTE, StatusPosNode } from "../../constants";

export default {
  name: "About",
  data() {
    return {
      conflux: null,
      posPoolContract: null, //矿池
      balance: "",
      activeNames: ["1"],
      activeNames2: ["1"],
      activeNames3: ["1"],
      activeNames4: ["1"],
      earned: "",

      amount: "",
      amount2: "",
      pooldata: "",
      setName: "",
      setApy: "",
      setLockedCfx: "",
      etTotalRevenue: "",
      acfx: 0,
      myrewards: 0,
      cfxCanWithdraw: 0,
      userfee: "",
      fee: "",
      locking: "",
      acfxsum: null,
      lockednum: null,
      acfxholders: null,
      locked: "",
      unlocked: "",
      acfxnum: "",
      allvote: 0,
      userInQueue: [],
      userOutQueue: [],

      data1: {
        labels: [],
        datasets: [
          {
            label: "ACFX holders",
            backgroundColor: "rgb(255, 0, 0)",
            borderColor: "rgb(255, 0, 0)",
            data: [],
          },
        ],
      },
      data2: {
        labels: [],
        datasets: [
          {
            label: "ACFX SUM [K]",
            backgroundColor: "rgb(0, 255, 0)",
            borderColor: "rgb(0, 255, 0)",
            data: [],
          },
        ],
      },
      data3: {
        labels: [],
        datasets: [
          {
            label: "Total Locked(CFX) [K]",
            backgroundColor: "#2286f7",
            borderColor: "#2286f7",
            data: [],
          },
        ],
      },
      data4: {
        labels: [],
        datasets: [
          {
            label: "APY [%]",
            backgroundColor: "#ff9800",
            borderColor: "#ff9800",
            data: [],
          },
        ],
      },
    };
  },
  mounted() {
    setTimeout(() => {
      const myChart = new Chart(document.getElementById("myChart"), {
        type: "line",
        data: this.data1,
        options: {
          scales: {
            y: {
              beginAtZero: true,
              stepSize: 1,
            },
          },
        },
      });
      const myChart2 = new Chart(document.getElementById("myChart2"), {
        type: "line",
        data: this.data2,
        options: {},
      });
      const myChart3 = new Chart(document.getElementById("myChart3"), {
        type: "line",
        data: this.data3,
        options: {},
      });
      const myChart4 = new Chart(document.getElementById("myChart4"), {
        type: "line",
        data: this.data4,
        options: {},
      });
    }, 1000);

    window.setMybalance = function (val) {};
    window.setMyearned = function (val) {};
    window.setMyprofit = function (val) {};
    window.setMyart = function (val) {};
    window.setMycoinname = function (val) {};
    window.setStakingbalance = function (val) {};
    this.screenWidth = 600; //document.body.clientWidth; // 屏幕宽
    this.init();
  },
  methods: {
    toConvert(size) {
      if (!size) return 0;

      var num = 1000.0; //byte

      if (size < Math.pow(num, 2)) return (size / num).toFixed(2) + "K"; //kb
      if (size < Math.pow(num, 3)) return (size / Math.pow(num, 2)).toFixed(2) + "M"; //M
      if (size < Math.pow(num, 4)) return (size / Math.pow(num, 3)).toFixed(2) + "G"; //G
      return (size / Math.pow(num, 4)).toFixed(2) + "T"; //T
    },
    toConvert2(size) {
      if (!size) return 0;

      var num = 1000; //byte

      if (size < Math.pow(num, 2)) return parseInt(size / num) + "K"; //kb
      if (size < Math.pow(num, 3)) return parseInt(size / Math.pow(num, 2)) + "M"; //M
      if (size < Math.pow(num, 4)) return parseInt(size / Math.pow(num, 3)) + "G"; //G
      return parseInt(size / Math.pow(num, 4)) + "T"; //T
    },
    toConvert3(size) {
      if (!size) return 0;

      var num = 1000.0; //byte

      if (size < Math.pow(num, 2)) return (size / num).toFixed(3) + "K"; //kb
      if (size < Math.pow(num, 3)) return (size / Math.pow(num, 2)).toFixed(3) + "M"; //M
      if (size < Math.pow(num, 4)) return (size / Math.pow(num, 3)).toFixed(3) + "G"; //G
      return (size / Math.pow(num, 4)).toFixed(3) + "T"; //T
    },
    async init() {
      this.$nextTick(function () {
        if (document.body.clientWidth >= 768) {
          this.$router.replace({ path: "/pool" });
        }

        this.$axios.get(this.$store.state.api + "/statistics").then((response) => {
          const array = response.data.rows;
          for (let index = array.length - 1; index >= 0; index--) {
            const element = array[index];
            //this.data.labels.push('aa');

            const data = JSON.parse(element.data);
            let d1 = data.data[1];
            if (d1.indexOf("M") > -1) {
              d1 = d1.replace("M", "") * 1000 + "K";
            }
            let d2 = data.data[2];
            if (d2.indexOf("M") > -1) {
              d2 = d2.replace("M", "") * 1000 + "K";
            }

            this.data1.datasets[0].data.push(+data.data[0]);
            this.data2.datasets[0].data.push(data.data[1] ? +d1.replace("K", "") : 0);
            this.data3.datasets[0].data.push(data.data[2] ? +d2.replace("K", "") : 0);
            this.data4.datasets[0].data.push(+data.data[3]);

            var oldTime = new Date(element.created_at).getTime();
            var curday = new Date(oldTime).getDate();
            var curmonth = new Date(oldTime).getMonth();
            curday = curmonth + 1 + "/" + curday;
            this.data1.labels.push(curday);
            this.data2.labels.push(curday);
            this.data3.labels.push(curday);
            this.data4.labels.push(curday);
          }
        });
      });

      const accountAddress = localStorage.getItem("_address"); //window._address; //登录用户 address
      window._address = accountAddress;

      /* ACFX 数量
      const ACFX = conflux.Contract({
        abi: ACFX_ABI,
        address: ACFX_ADDRESS,
      });
      const acfx_num = await ACFX.balanceOf(accountAddress);
      this.acfx = acfx_num.toString();
 */
      // 某个矿池 "cfxtest:acey3u0twyjyt50225h5gk7jdnujje6dtjb20ue502"
      this.posPoolContract = getPosPoolContract(POSPOOL_ADDRESS);
      console.log(this.posPoolContract);

      const proArr = [];
      proArr.push(this.posPoolContract.userSummary(accountAddress));
      proArr.push(this.posPoolContract.userInterest(accountAddress)); // VotePower
      proArr.push(this.posPoolContract.poolUserShareRatio()); // Total Locked(CFX)
      proArr.push(this.posPoolContract.userOutQueue(accountAddress));
      const data = await Promise.all(proArr);
      const userSum = data[0];
      this.acfx = parseFloat(Drip(+userSum[6]).toCFX()).toFixed(
        2
      ); /*new BigNumber(userSum[6] || 0)
        .multipliedBy(CFX_BASE_PER_VOTE)
        .toString(10);*/

      this.allvote = +userSum[1];
      this.locking = userSum[1] - userSum[2];
      this.locked = +userSum[2];

      const unlocked = Drip(+userSum[3]).toCFX(); // 获取 CFX
      const myunlocked = parseFloat(unlocked).toFixed(2);
      this.unlocked = myunlocked;

      this.myrewards = getPrecisionAmount(
        new Drip(new BigNumber(data[1]).toString(10)).toCFX(),
        2
      );

      this.cfxCanWithdraw = getCfxByVote(userSum[3] || 0);

      this.fee = getFee(data[2]);

      const userfee = await this.posPoolContract
        .userShareRatio()
        .call({ from: accountAddress });
      this.userfee = getFee(userfee);

      // Balance
      const balance = await conflux.cfx.getBalance(window._address);
      const tbalance = Drip(balance).toCFX(); // 获取 CFX
      const mybalance = parseFloat(tbalance).toFixed(2);
      this.balance = mybalance;

      const proArr2 = [];
      proArr2.push(this.posPoolContract.poolSummary());
      proArr2.push(this.posPoolContract.poolAPY());
      proArr2.push(this.posPoolContract.poolName());
      const data2 = await Promise.all(proArr2);
      const poolSummary = data2[0];

      this.acfxholders = +poolSummary[3];
      this.acfxsum = this.toConvert2(parseInt(Drip(+poolSummary[4]).toCFX()));

      this.setLockedCfx = this.toConvert3(getCfxByVote(poolSummary[0]));
      this.etTotalRevenue = this.toConvert(
        parseFloat(
          new Drip(new BigNumber(poolSummary[2]).toNumber()).toCFX() //.minus(poolSummary[1])
        ).toFixed(2)
      );
      this.setApy = getApy(data2[1]);
      this.setName = data2[2];

      const userInQueue = await this.posPoolContract.userInQueue(accountAddress);
      this.userInQueue = [];
      userInQueue.forEach((element) => {
        const array = [element[0], element[1]];
        this.userInQueue.push(array);
      });

      const userOutQueue = await this.posPoolContract.userOutQueue(accountAddress);
      this.userOutQueue = [];
      userOutQueue.forEach((element) => {
        const array = [element[0], element[1]];
        this.userOutQueue.push(array);
      });
      //console.log(userInQueue);
    },
    async getACFX() {
      if (this.lockednum === "") {
        this.$toast.fail("Enter number");
        return;
      }
      if (+this.lockednum === 0) {
        this.$toast.fail("can't input zero");
        return;
      }
      if (this.lockednum > this.locked) {
        this.$toast.fail("Cannot be greater than ACFX");
        return;
      }
      this.$toast.loading({
        message: "processing...",
        duration: 0,
        overlay: true,
        forbidClick: true,
      });
      try {
        const data = await this.posPoolContract.get_ACFX(this.lockednum).sendTransaction({
          from: window._address,
        });
      } catch (error) {
        console.log(error);
        this.$toast.clear();
      }

      setTimeout(() => {
        this.$toast.clear();
        this.init();
      }, 10000);
    },
    async getRewards() {
      const data = await this.posPoolContract
        .claimAllInterest()
        .sendTransaction({
          from: window._address,
        })
        .executed();
      setTimeout(() => {
        this.init();
      }, 10000);
    },
    async getWithdraw() {
      const collateral = await this.posPoolContract
        .withdrawStake(+this.cfxCanWithdraw / 1000)
        .estimateGasAndCollateral({
          from: window._address,
        });

      const data = await this.posPoolContract
        .withdrawStake(+this.cfxCanWithdraw / 1000)
        .sendTransaction({
          gas: collateral.gasLimit,
          storageLimit: collateral.storageCollateralized,
          from: window._address,
        })
        .executed();
      setTimeout(() => {
        this.init();
      }, 10000);
    },
    async exchangeToCFX() {
      if (this.acfxnum === "") {
        this.$toast.fail("Enter number");
        return;
      }
      if (+this.acfxnum === 0) {
        this.$toast.fail("can't input zero");
        return;
      }
      if (this.acfxnum > this.acfx) {
        this.$toast.fail("Cannot be greater than acfx");
        return;
      }

      this.$toast.loading({
        message: "processing, Do not refresh...",
        duration: 0,
        overlay: true,
        forbidClick: true,
      });

      const acfxnum = this.acfxnum / 1000;
      const collateral = await this.posPoolContract
        .burn_ACFX(acfxnum)
        .estimateGasAndCollateral({
          from: window._address,
        });
      const data = await this.posPoolContract
        .burn_ACFX(acfxnum)
        .sendTransaction({
          gas: collateral.gasLimit,
          storageLimit: collateral.storageCollateralized,
          from: window._address,
        })
        .executed();
      setTimeout(() => {
        this.$toast.clear();
        this.init();
      }, 5000);
    },
    // 质押
    async onStake() {
      if (this.amount === "") {
        this.$toast.fail("Enter number");
        return;
      }
      if (+this.amount === 0) {
        this.$toast.fail("can't input zero");
        return;
      }
      if (+this.amount > +this.balance) {
        this.$toast.fail("Cannot be greater than balance");
        return;
      }
      if (this.amount % 1000 > 0) {
        this.$toast.fail("1000 的整数倍");
        return;
      }

      //return;
      const vote = this.amount / 1000;
      const cfx = Drip.fromCFX(this.amount);
      const collateral = await this.posPoolContract
        .increaseStake(vote)
        .estimateGasAndCollateral({
          value: cfx,
          from: window._address,
        });

      const data = await this.posPoolContract
        .increaseStake(vote)
        .sendTransaction({
          gas: collateral.gasLimit,
          storageLimit: collateral.storageCollateralized,
          value: cfx,
          from: window._address,
        })
        .executed();

      setTimeout(() => {
        this.init();
      }, 10000);
    },
    // 解押
    async onUnStake() {
      if (this.amount2 === "") {
        this.$toast.fail("Enter number");
        return;
      }
      if (+this.amount2 === 0) {
        this.$toast.fail("can't input zero");
        return;
      }
      if (+this.amount > +this.balance) {
        this.$toast.fail("Cannot be greater than balance");
        return;
      }
      if (this.amount % 1000 > 0) {
        this.$toast.fail("1000 的整数倍");
        return;
      }

      const vote = this.amount2 / 1000;
      //const cfx = Drip.fromCFX(this.amount2);
      console.log(vote);
      const collateral = await this.posPoolContract
        .decreaseStake(vote)
        .estimateGasAndCollateral({
          from: window._address,
        });
      console.log(collateral);
      const data = await this.posPoolContract
        .decreaseStake(vote)
        .sendTransaction({
          gas: collateral.gasLimit,
          storageLimit: collateral.storageCollateralized,
          //value: cfx,
          from: window._address,
        })
        .executed();

      setTimeout(() => {
        this.init();
      }, 10000);
    },
    max1() {
      this.amount = Math.floor(this.balance / 1000) * 1000;
    },
    max2() {
      this.amount2 = Math.floor(this.locked) * 1000;
    },
  },
};
